import React from "react";
import { Row, Col } from "antd";
import "aos/dist/aos.css";
import AOS from "aos";

const HowItWorks = () => {
  // Initialize AOS library
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-900 text-white py-10" id="how-it-works">
      <div className="max-w-7xl mx-auto px-6 sm:px-10 md:px-14">
        <h2 className="text-3xl md:text-4xl font-bold text-yellow-500 text-center mb-10" data-aos="fade-up">
          How It Works
        </h2>
        <Row gutter={16} justify="center">
          <Col xs={24} sm={12} md={6}>
            <HowItWorksCard
              title="Free Consultation"
              description="Get in touch for a consultation to discuss your project and needs."
              delay={200}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <HowItWorksCard
              title="Understanding Your Vision"
              description="Collaborate to explore and clarify your ideas and objectives."
              delay={400}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <HowItWorksCard
              title="Design & Development"
              description="Transform your ideas into a functional design and build the final product."
              delay={600}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <HowItWorksCard
              title="Launch & Support"
              description="Launch your project and provide ongoing support for success."
              delay={800}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const HowItWorksCard = ({ title, description, delay }) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay={delay}
      className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6 sm:mb-8 transition-transform duration-300 transform hover:scale-105"
    >
      <h3 className="text-xl font-bold mb-2 text-center text-yellow-500">{title}</h3>
      <p className="text-gray-300 text-center">{description}</p>
    </div>
  );
};

export default HowItWorks;
