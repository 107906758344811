import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row, Spin, message, Button, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import BlogCC from '../components/Blog/BlogC';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { Meta } = Card;

const BlogC = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs();
    AOS.init(); // Initialize AOS animations
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://myportfolio.ethioalert.com/api/posts');
      setBlogs(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      message.error('Failed to fetch blogs');
      setLoading(false);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const handleReadMore = (id) => {
    navigate(`/blog/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className='bg-slate-800'>
      <BlogCC />
      <div className="container mx-auto p-4 ">
        <h2 className="text-4xl font-bold mb-8 text-center text-yellow-500" data-aos="fade-up">Blogs</h2>
        <Row gutter={[16, 16]}>
          {currentItems.map((blog) => (
            <Col key={blog._id} xs={24} sm={12} md={8} data-aos="fade-up" data-aos-duration="1000">
              <Card
                hoverable
                cover={
                  <img
                    alt={blog.title}
                    src={`https://myportfolio.ethioalert.com/${blog.imageURL}`}
                    style={{ height: '250px', objectFit: 'fit' }}
                    data-aos="zoom-in" // Add animation to the image
                    data-aos-duration="1000"
                    className=''
                  />
                }
                style={{ borderRadius: '10px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
              >
                <Meta
                  title={<h3 className="text-xl font-semibold">{truncateText(blog.title, 100)}</h3>}
                  description={
                    <>
                      <p className="text-sm text-gray-700">{truncateText(blog.content, 350)}</p>
                      <p className="text-xs text-gray-500 mt-2">{new Date(blog.date).toLocaleDateString()}</p>
                      <Button type="link" className="font-bold bg-blue-700 text-white" onClick={() => handleReadMore(blog._id)}>
                        Read More
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
        <div className="flex justify-center mt-8">
          <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={blogs.length}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogC;