import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MissionVision = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="py-12 bg-blue-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center" data-aos="fade-up">
          <h2 className="text-3xl font-extrabold text-yellow-500 sm:text-4xl">
            My Commitment to the Community
          </h2>
          <p className="mt-4 text-lg text-white max-w-3xl mx-auto">
            I believe in the importance of giving back to the community and making a positive impact through various initiatives. My commitment extends to supporting nonprofits, engaging in community activities, and encouraging volunteer work.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl font-bold text-gray-100 text-center" data-aos="fade-up" data-aos-delay="200">
            My Initiatives
          </h3>
          <div className="mt-6 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="400">
              <h4 className="text-xl font-semibold text-gray-800">Supporting Nonprofits</h4>
              <p className="mt-2 text-gray-600">
                I collaborate with various nonprofit organizations to support their missions, focusing on education, healthcare, and technology access, and strive to make a meaningful impact.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="600">
              <h4 className="text-xl font-semibold text-gray-800">Community Engagement</h4>
              <p className="mt-2 text-gray-600">
                Actively participating in local events and projects to improve the quality of life in my neighborhood is a priority for me. I believe in the power of community and aim to contribute positively.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="800">
              <h4 className="text-xl font-semibold text-gray-800">Encouraging Volunteerism</h4>
              <p className="mt-2 text-gray-600">
                I encourage my peers and team members to volunteer their time and skills for causes they are passionate about, believing that meaningful engagement can lead to personal and community growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
