import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Pagination, Modal } from 'antd';

function Users() {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); // Number of items per page
  const [selectedContact, setSelectedContact] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('https://myportfolio.ethioalert.com/api/contacts');
        const sortedContacts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setContacts(sortedContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleView = (contact) => {
    setSelectedContact(contact);
    setViewModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://myportfolio.ethioalert.com/api/contacts/${id}`);
      setContacts(contacts.filter((contact) => contact._id !== id));
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  const columns = [
    { title: 'First Name', dataIndex: 'firstname', key: 'firstname' },
    { title: 'Last Name', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Phone Number', dataIndex: 'phoneno', key: 'phoneno' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Message', dataIndex: 'message', key: 'message', render: (text) => text.length > 70 ? `${text.substring(0, 70)}...` : text },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="flex">
          <Button type="primary" className="mr-2" onClick={() => handleView(record)}>View</Button>
          <Button type="button" className="bg-red-600 p-2 text-white" onClick={() => handleDelete(record._id)}>Delete</Button>
        </div>
      ),
    },
  ];

  const currentData = contacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold text-blue-800 mb-6">Contacts</h1>
      <Table
        dataSource={currentData}
        columns={columns}
        pagination={false}
        rowKey={(record) => record._id}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={contacts.length}
        onChange={handlePageChange}
        className="mt-4"
      />
      <Modal
        visible={viewModalVisible}
        title="Contact Details"
        onCancel={() => setViewModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setViewModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedContact && (
          <div>
            <p className="mb-2"><strong>First Name:</strong> {selectedContact.firstname}</p>
            <p className="mb-2"><strong>Last Name:</strong> {selectedContact.lastname}</p>
            <p className="mb-2"><strong>Phone Number:</strong> {selectedContact.phoneno}</p>
            <p className="mb-2"><strong>Email:</strong> {selectedContact.email}</p>
            <p className="mb-2"><strong>Message:</strong> {selectedContact.message}</p>
            <p className="mb-2"><strong>Created At:</strong> {new Date(selectedContact.createdAt).toLocaleString()}</p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Users;
