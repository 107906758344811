import React from "react";
import Banner from "../../assets/TechBanner.jpg"; // Replace with your personal banner image path
import "aos/dist/aos.css";
import AOS from "aos";

const About = () => {
  // Initialize AOS library
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="relative">
      {/* Banner Image */}
      <img src={Banner} alt="banner" className="w-full h-48 object-cover md:h-64" data-aos="fade-in" />

      {/* About Me Text */}
      <div
        className="absolute inset-0 flex flex-col justify-center items-center text-center px-4 py-2 bg-gradient-to-t from-black to-transparent"
        data-aos="fade-up"
      >
        <h1 className="font-bold text-2xl md:text-4xl text-white mb-2">
          About Me
        </h1>
        <h3 className="text-white text-sm md:text-lg max-w-2xl">
          I’m a passionate developer dedicated to simplifying technology, crafting innovative solutions, and enhancing user experiences.
        </h3>
      </div>
    </div>
  );
};

export default About;
