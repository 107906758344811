import React, { useEffect } from 'react';
import { CodeOutlined, CameraOutlined, PictureOutlined, ShareAltOutlined, LaptopOutlined } from "@ant-design/icons";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Services() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-slate-800 text-white py-10">
      <div className="max-w-7xl mx-auto px-6 sm:px-10 md:px-14">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-yellow-500 mb-10" data-aos="fade-up">
          MY SERVICES
        </h2>
        <p className="text-lg text-center text-gray-100 mb-10 max-w-3xl mx-auto" data-aos="fade-up" data-aos-delay="200">
          Let me handle the details, so you can focus on what matters. I offer transformative Web Development, creative Logo Design, engaging Video Editing, effective Social Media Management, and IT Training services – unleashing limitless potential.
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="400">
            <CodeOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Web Development</h3>
            <p className="text-gray-100">
              Crafting and enhancing your online presence with innovative solutions.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="600">
            <PictureOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Logo Design</h3>
            <p className="text-gray-100">
              Creating memorable logos that resonate with your brand identity.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="800">
            <CameraOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Video Editing</h3>
            <p className="text-gray-100">
              Bringing your stories to life with professional video editing services.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="1000">
            <ShareAltOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Social Media Management</h3>
            <p className="text-gray-100">
              Managing your online presence and engagement across social platforms.
            </p>
          </div>

          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="1200">
            <LaptopOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">IT Trainer</h3>
            <p className="text-gray-100">
              Providing professional courses and system training to equip teams with the skills they need to succeed in tech.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
