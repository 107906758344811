import { Button } from "antd";
import { LinkedinOutlined, WhatsAppOutlined, SendOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Senay from "../../assets/Senay A.png"; // Your personal image

function PersonalSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-slate-800 text-white py-10">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center md:items-start px-6 sm:px-10 md:px-14">
        <div className="md:w-1/2 md:pr-10" data-aos="fade-right">
          <h2 className="text-3xl md:text-4xl font-bold text-yellow-500">Meet Senay Alemneh</h2>
          <p className="py-5 text-lg">
          I am a passionate Full-Stack Web Developer and Graphic Designer creating exceptional digital experiences. Driven by a desire to use technology to solve problems and create opportunities, I specialize in website development, Graphics Design, IT Training and Social Media Management.
          </p>
          <p className="py-2 text-lg">
          Committed to delivering innovative solutions, I tailor my work to meet the unique needs of each client. I believe that technology has the power to boost productivity and enrich lives, and I strive to bring that vision to every project.
          </p>
          <div className="flex space-x-4 mt-4">
            <a href="https://telegram.me/senay1992" target="_blank" rel="noopener noreferrer">
              <Button icon={<SendOutlined />} className="bg-blue-600 text-white hover:bg-blue-700" shape="circle" />
            </a>
            <a href="https://linkedin.com/in/senayalemneh" target="_blank" rel="noopener noreferrer">
              <Button icon={<LinkedinOutlined />} className="bg-blue-700 text-white hover:bg-blue-800" shape="circle" />
            </a>
            <a href="https://wa.me/+1234567890" target="_blank" rel="noopener noreferrer">
              <Button icon={<WhatsAppOutlined />} className="bg-green-500 text-white hover:bg-green-600" shape="circle" />
            </a>
          </div>
        </div>
        <div className="md:w-1/2 flex justify-center mt-8 md:mt-0" data-aos="fade-left">
          <img
            src={Senay}
            alt="Senay Alemneh"
            className="rounded-lg object-cover w-full h-auto max-w-md"
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalSection;
