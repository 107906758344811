import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { FaUser } from 'react-icons/fa';
import axios from 'axios'; // Import axios if using it

const CustomerTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    // Fetching data from the API
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://myportfolio.ethioalert.com/api/testimonials');
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <section className="bg-slate-700 dark:bg-gray-900 py-12">
      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
        <h2 className="text-3xl font-extrabold text-white dark:text-white mb-8">Testimonials from My Clients!</h2>
        <Carousel
          autoplay
          dots={false}
          slidesToShow={3}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="px-4">
              <figure className="flex flex-col justify-between max-w-xs mx-auto bg-white p-6 rounded-lg shadow-md dark:bg-gray-800 h-[400px]">
                <svg
                  className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote className="flex-1">
                  <p className="text-2xl font-medium text-gray-900 dark:text-white">{testimonial.text}</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                  <FaUser className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                    <div className="pr-3 font-medium text-gray-900 dark:text-white">{testimonial.name}</div>
                    <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">{testimonial.role}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default CustomerTestimonials;
