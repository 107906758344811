import React from "react";
import { FaDesktop, FaPaintBrush, FaVideo, FaUsers, FaChalkboardTeacher } from "react-icons/fa"; // Add FaChalkboardTeacher for IT Training
import WD from "../../assets/Home/Worldthree.png"; // Replace with actual image path
import AOS from "aos";
import "aos/dist/aos.css";

const ServicesOverview = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="bg-slate-700 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center">
        
        {/* Services Overview Text Section */}
        <div className="w-full lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-2xl lg:text-3xl font-bold text-yellow-500 mb-6" data-aos="fade-up">
            My Services
          </h2>
          <p className="text-lg lg:text-base text-white mb-10" data-aos="fade-up" data-aos-delay="100">
            As a passionate developer and designer, I offer personalized services to help you elevate your digital presence. Explore my expertise in Web Development, Logo Design, Video Editing, Social Media Management, and IT Training.
          </p>

          {/* Service 1: Web Development */}
          <div className="mb-8" data-aos="fade-up" data-aos-delay="200">
            <div className="flex items-center mb-3">
              <FaDesktop className="text-4xl text-blue-600 mr-4" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Web Development
              </h3>
            </div>
            <p className="text-sm lg:text-base text-gray-300">
              Crafting custom and responsive websites tailored to your needs, ensuring a strong online presence.
            </p>
          </div>

          {/* Service 2: Logo Design */}
          <div className="mb-8" data-aos="fade-up" data-aos-delay="300">
            <div className="flex items-center mb-3">
              <FaPaintBrush className="text-4xl text-blue-600 mr-4" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Logo Design
              </h3>
            </div>
            <p className="text-sm lg:text-base text-gray-300">
              Creating unique and memorable logos that capture your brand's identity and essence.
            </p>
          </div>

          {/* Service 3: Video Editing */}
          <div className="mb-8" data-aos="fade-up" data-aos-delay="400">
            <div className="flex items-center mb-3">
              <FaVideo className="text-4xl text-blue-600 mr-4" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Video Editing
              </h3>
            </div>
            <p className="text-sm lg:text-base text-gray-300">
              Providing professional video editing services to create compelling content for your audience.
            </p>
          </div>

          {/* Service 4: Social Media Management */}
          <div className="mb-8" data-aos="fade-up" data-aos-delay="500">
            <div className="flex items-center mb-3">
              <FaUsers className="text-4xl text-blue-600 mr-4" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Social Media Management
              </h3>
            </div>
            <p className="text-sm lg:text-base text-gray-300">
              Managing your social media presence to enhance engagement and grow your audience effectively.
            </p>
          </div>

          {/* New Service 5: IT Training */}
          <div className="mb-8" data-aos="fade-up" data-aos-delay="600">
            <div className="flex items-center mb-3">
              <FaChalkboardTeacher className="text-4xl text-blue-600 mr-4" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                IT Training
              </h3>
            </div>
            <p className="text-sm lg:text-base text-gray-300">
              Offering professional IT training on courses and systems to equip teams with the skills needed to succeed in tech.
            </p>
          </div>
        </div>

        {/* Image Section */}
        <div className="w-full lg:w-1/2">
          <img
            src={WD} // Replace with the appropriate image for Web Development
            alt="Web Development"
            className="rounded-lg h-auto"
            data-aos="fade-left"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesOverview;
