import { Button } from "antd";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutDesc() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-slate-800 py-10">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 md:px-10 lg:px-12">
        <div data-aos="fade-up">
          <h2 className="text-yellow-400 text-2xl py-2 text-center md:text-left font-semibold">
            WHO I AM
          </h2>
          <h1 className="text-4xl md:text-5xl font-extrabold text-white text-center md:text-left">
            I’m Senay Alemneh - Your Digital Partner
          </h1>
          <p className="py-6 text-gray-200 text-lg md:text-xl text-center md:text-left leading-relaxed">
              I am passionate about transforming digital experiences for businesses and individuals. Specializing in web development, graphic design, and social media management, my focus is on delivering innovative, tailored solutions that meet the unique needs of each client.
            <br /><br />
            I believe in the power of technology to drive progress and foster growth across all sectors. My mission is to leverage my expertise to help pave the way toward a digital future. By harnessing cutting-edge technology, I strive to create new opportunities and enhance the quality of life for communities throughout the region.
            <br /><br />
            My work is driven by a deep passion for crafting functional, user-friendly solutions. I take pride in my client-centered approach, listening carefully to understand specific needs and delivering customized solutions that exceed expectations.
            <br /><br />
            Whether you're a small business, a large corporation, or an individual looking to achieve your goals through technology, I am here to support you. Let's collaborate to explore how my skills and services can empower you to reach your digital ambitions. Feel free to get in touch, and together, we’ll unlock new possibilities.
          </p>
        </div>
        {/* <div
          className="bg-slate-900 rounded-lg p-8 shadow-lg mt-10"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="mb-8">
            <h3 className="text-2xl font-bold text-yellow-400">Mission</h3>
            <p className="text-lg text-gray-300 mt-2">
              To empower individuals and businesses through innovative technology solutions that enhance efficiency, connectivity, and digital accessibility.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-yellow-400">Vision</h3>
            <p className="text-lg text-gray-300 mt-2">
              To lead the digital transformation journey by leveraging cutting-edge technology, fostering growth, and enriching lives through sustainable digital solutions.
            </p>
          </div>
        </div> */}
        <div className="text-center md:text-left pt-6">
          <a href="/contact">
            <Button className="bg-blue-700 text-white font-bold py-3 px-6 mt-6 hover:bg-blue-800 transition-all duration-300 text-lg rounded-md shadow-md">
              Contact Me
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutDesc;
