import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Space, Form, Input, Upload, message, Pagination } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddPortfolioForm = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [webList, setWebList] = useState([]);
  const [graphicsList, setGraphicsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [updateFileList, setUpdateFileList] = useState([]);
  const [itemType, setItemType] = useState('web'); // 'web' or 'graphic'

  useEffect(() => {
    fetchItems();
  }, [itemType]);

  const fetchItems = async () => {
    try {
      const url = itemType === 'web' ? 'https://myportfolio.ethioalert.com/api/portfolios' : 'https://myportfolio.ethioalert.com/api/portfoliosgraphics';
      const response = await axios.get(url);
      itemType === 'web' ? setWebList(response.data) : setGraphicsList(response.data);
    } catch (error) {
      console.error(`Error fetching ${itemType} items:`, error);
      message.error(`Failed to fetch ${itemType} items`);
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));
  const handleUpdateFileChange = ({ fileList }) => setUpdateFileList(fileList.slice(-1));

  const handleAddSubmit = async (values) => {
    if (!values.projectName || !values.caseStudy || !values.description || !fileList.length) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    const formData = new FormData();
    formData.append('projectName', values.projectName);
    formData.append('caseStudy', values.caseStudy);
    formData.append('description', values.description);
    formData.append('image', fileList[0].originFileObj);

    try {
      const url = itemType === 'web' ? 'https://myportfolio.ethioalert.com/api/portfolios' : 'https://myportfolio.ethioalert.com/api/portfoliosgraphics';
      await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      message.success('Item added successfully');
      fetchItems();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      console.error('Error adding item:', error);
      message.error('Failed to add item');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const url = itemType === 'web' ? `https://myportfolio.ethioalert.com/api/portfolios/${id}` : `https://myportfolio.ethioalert.com/api/portfoliosgraphics/${id}`;
        await axios.delete(url);
        message.success('Item deleted successfully');
        fetchItems();
      } catch (error) {
        console.error('Error deleting item:', error);
        message.error('Failed to delete item');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Item',
      width: 800,
      content: (
        <div>
          <p><strong>Project Name:</strong> {record.projectName}</p>
          <p><strong>Link:</strong> {record.caseStudy}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>Image:</strong></p>
          <img src={`https://myportfolio.ethioalert.com/${record.image}`} alt={record.projectName} style={{ width: '100%', maxHeight: '400px' }} />
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentItem(record);
    updateForm.setFieldsValue({
      projectName: record.projectName,
      caseStudy: record.caseStudy,
      description: record.description,
    });
    setUpdateFileList([]);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('projectName', values.projectName);
    formData.append('caseStudy', values.caseStudy);
    formData.append('description', values.description);
    if (updateFileList.length > 0) {
      formData.append('image', updateFileList[0].originFileObj);
    }

    try {
      const url = itemType === 'web' ? `https://myportfolio.ethioalert.com/api/portfolios/${currentItem._id}` : `https://myportfolio.ethioalert.com/api/portfoliosgraphics/${currentItem._id}`;
      await axios.put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      message.success('Item updated successfully');
      fetchItems();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating item:', error);
      message.error('Failed to update item');
    }
  };

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Link',
      dataIndex: 'caseStudy',
      key: 'caseStudy',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleView(record)}>View</Button>
          <Button onClick={() => handleUpdate(record)}>Update</Button>
          <Button danger onClick={() => handleDelete(record._id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = itemType === 'web' ? webList.slice(startIndex, endIndex) : graphicsList.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Portfolio Manager</h2>

      <div className="mb-4">
        <Button type="primary" onClick={() => setItemType('web')}>Manage Web Items</Button>
        <Button type="primary" onClick={() => setItemType('graphic')}>Manage Graphic Items</Button>
      </div>

      <Form form={form} onFinish={handleAddSubmit}>
        <Form.Item name="projectName" rules={[{ required: true, message: 'Please enter the project name' }]}>
          <Input placeholder="Project Name" />
        </Form.Item>
        <Form.Item name="caseStudy" rules={[{ required: true, message: 'Please enter the case study' }]}>
          <Input placeholder="Link" />
        </Form.Item>
        <Form.Item name="description" rules={[{ required: true, message: 'Please enter the description' }]}>
          <TextArea placeholder="Description" rows={4} />
        </Form.Item>
        <Form.Item name="image" rules={[{ required: true, message: 'Please upload an image' }]}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Item
          </Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={paginatedData} pagination={false} rowKey="_id" />

      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={itemType === 'web' ? webList.length : graphicsList.length}
        onChange={handlePaginationChange}
        className="mt-4 text-center"
      />

      <Modal title="Update Item" visible={isUpdateModalVisible} onCancel={() => setIsUpdateModalVisible(false)} onOk={() => updateForm.submit()}>
        <Form form={updateForm} onFinish={handleUpdateSubmit}>
          <Form.Item name="projectName" rules={[{ required: true, message: 'Please enter the project name' }]}>
            <Input placeholder="Project Name" />
          </Form.Item>
          <Form.Item name="caseStudy" rules={[{ required: true, message: 'Please enter the case study' }]}>
            <Input placeholder="Link" />
          </Form.Item>
          <Form.Item name="description" rules={[{ required: true, message: 'Please enter the description' }]}>
            <TextArea placeholder="Description" rows={4} />
          </Form.Item>
          <Form.Item name="image">
            <Upload fileList={updateFileList} onChange={handleUpdateFileChange} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPortfolioForm;
