import React, { useEffect } from "react";
import SD from "../../assets/Home/softwaredev.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjusted duration
      once: true,
    });
  }, []);

  return (
    <section className="bg-yellow-600 dark:bg-gray-900">
      <div
        className="flex flex-col-reverse lg:flex-row items-center max-w-screen-xl px-4 mx-auto lg:gap-8 xl:gap-12"
        data-aos="fade-up"
      >
        <div className="flex-1 text-center lg:text-left lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
            Building digital <br />
            products &amp; brands.
          </h1>
          <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Crafting Digital Solutions to Elevate Your Brand. Innovative Digital
            Services for Enhanced Business Performance.
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 justify-center lg:justify-start">
            <a
              href="/contact"
              target="_blank"
              className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              rel="noopener noreferrer"
            >
              Contact Me
            </a>
          </div>
        </div>
        <div
          className="flex-1 flex justify-center lg:justify-end mb-6 lg:mb-0"
          data-aos="fade-left"
        >
          <img
            src={SD}
            className="w-full max-w-sm lg:max-w-lg "
            alt="Software Development"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
