import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import Video from "../../assets/Video/senaylanding.mp4";
import { Button } from "antd";
import { Helmet } from 'react-helmet';
import Senay from "../../assets/Home/SenayImage.png"; // Adjust the path if needed

function Home() {
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with desired settings
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.3; // Adjust this value to set the desired playback speed
    }
  }, []);

  return (
    <div className="relative w-full h-auto overflow-hidden">
      <Helmet>
        <title>Home - Senay Alemneh</title>
        <meta name="description" content="Home page of Senay Alemneh, Full-Stack Web Developer and Graphics Designer." />
        <meta name="keywords" content="home, Senay Alemneh, Full-Stack Web Developer, Graphics Designer" />
        <meta property="og:title" content="Home - Senay Alemneh" />
        <meta property="og:description" content="Welcome to my personal website. Explore my work and passion for design and development." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1] backdrop-blur-2xl"
        src={Video}
        autoPlay
        loop
        muted
      />
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="flex flex-col lg:flex-row w-full max-w-7xl mx-auto gap-6 rounded-lg shadow-lg overflow-hidden bg-black bg-opacity-50 p-6 lg:p-12">
          <div className="lg:w-1/2 flex flex-col justify-center items-start" data-aos="fade-right">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-4 text-[#FF7F00] font-bold">
              Hi, my name is <span className="text-[#1A7FC3]">Senay Alemneh</span>
            </h1>
            <h2 className="text-2xl sm:text-3xl md:text-4xl mb-2 text-white font-semibold">
              I'm a Full-Stack Web Developer and Graphics Designer
            </h2>
            <p className="text-base sm:text-lg md:text-xl mb-4 text-white font-medium">
              I am passionate about crafting exceptional digital experiences. My expertise lies in building responsive web applications and creating stunning graphic artworks, including logos, intros, and brochures. Currently, my primary focus revolves around the meticulous development of engaging web interfaces and the design of captivating visual elements.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a href="/portfolio">
                <Button className="bg-blue-800 text-white text-lg lg:text-xl p-4 mt-2" data-aos="zoom-in">
                  View Work
                </Button>
              </a>
              <a href="/about">
                <Button className="bg-blue-800 text-white text-lg lg:text-xl p-4 mt-2" data-aos="zoom-in">
                  About
                </Button>
              </a>
            </div>
          </div>
          <div className="lg:w-1/2 flex justify-center items-center p-4 overflow-hidden" data-aos="fade-left">
            <img
              src={Senay}
              className="rounded-full w-3/4 h-auto max-w-xs lg:max-w-full"
              alt="Senay Alemneh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
