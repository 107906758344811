import React, { useEffect } from "react";
import { FaRegLightbulb, FaUserFriends, FaChartLine, FaCog } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProcessCard = ({ icon, title, description, aosDelay }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg p-6 flex flex-col justify-between" data-aos="fade-up" data-aos-delay={aosDelay}>
      <div className="flex items-center mb-4">
        {icon}
        <h3 className="ml-3 text-xl font-bold text-gray-900 dark:text-white">{title}</h3>
      </div>
      <p className="text-gray-700 dark:text-gray-300 flex-grow">{description}</p>
    </div>
  );
};

const HowItWorks = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const processes = [
    {
      icon: <FaRegLightbulb className="text-4xl text-blue-600" />,
      title: "Identify Your Needs",
      description:
        "I help you understand your goals and requirements to find the right solutions.",
      aosDelay: 100,
    },
    {
      icon: <FaUserFriends className="text-4xl text-blue-600" />,
      title: "Personal Consultation",
      description:
        "Engage in a personalized consultation to discuss your vision and ideas.",
      aosDelay: 300,
    },
    {
      icon: <FaChartLine className="text-4xl text-blue-600" />,
      title: "Design & Develop",
      description:
        "I create and develop tailored web applications and graphics that align with your brand.",
      aosDelay: 500,
    },
    {
      icon: <FaCog className="text-4xl text-blue-600" />,
      title: "Deliver & Support",
      description:
        "I ensure a smooth launch and provide ongoing support to optimize your digital presence.",
      aosDelay: 700,
    },
  ];

  return (
    <section className="bg-blue-800 dark:bg-gray-900 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl lg:text-4xl font-extrabold text-white dark:text-white mb-8 text-center" data-aos="fade-up" data-aos-duration="1200">
          How I Work
        </h2>
        <h3 className="text-2xl lg:text-xl font-extrabold text-white dark:text-white mb-8 text-center" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
          I offer personalized digital services to bring your ideas to life.
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {processes.map((process, index) => (
            <div key={index} className="flex"> {/* Ensure equal height */}
              <ProcessCard
                icon={process.icon}
                title={process.title}
                description={process.description}
                aosDelay={process.aosDelay}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
