import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import WD from "../../assets/Home/webdevelopment.jpg"; // Ensure you have the correct image path for Web Development
import LD from "../../assets/Home/logologo.jpg"; // Add the image path for Logo Design
import VE from "../../assets/Home/VideoEditing.webp"; // Add the image path for Video Editing
import SM from "../../assets/Home/SocialMedia.jpeg"; // Add the image path for Social Media Management
import IT from "../../assets/Home/GraphicsDesign.jpg"; // Add the image path for IT Training

const ServiceCard = ({ title, description, imageUrl, aosType, aosDelay }) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjusted duration
      once: true,
    });
  }, []);

  return (
    <div
      className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4 bg-blue-900 text-yellow-400"
      data-aos={aosType}
      data-aos-delay={aosDelay}
    >
      <img
        className="w-full h-48 sm:h-64 object-cover object-center"
        src={imageUrl}
        alt={title}
      />
      <div className="px-6 py-4">
        <div
          className="font-bold text-xl mb-2"
          data-aos={aosType}
          data-aos-delay={aosDelay}
        >
          {title}
        </div>
        <p
          className="text-gray-100 text-base"
          data-aos={aosType}
          data-aos-delay={parseInt(aosDelay) + 100}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjusted duration
      once: true,
    });
  }, []);

  const services = [
    {
      title: "Web Development",
      description:
        "I create responsive and scalable web applications tailored to your business needs.",
      imageUrl: WD,
      aosType: "fade-up",
      aosDelay: "100",
    },
    {
      title: "Logo Design",
      description:
        "Crafting unique and memorable logos that represent your brand identity.",
      imageUrl: LD, // Make sure to provide the correct path to your logo design image
      aosType: "fade-down",
      aosDelay: "200",
    },
    {
      title: "Video Editing",
      description:
        "Professional video editing services to bring your vision to life.",
      imageUrl: VE, // Make sure to provide the correct path to your video editing image
      aosType: "fade-left",
      aosDelay: "300",
    },
    {
      title: "Social Media Management",
      description:
        "Managing your social media presence to engage your audience effectively.",
      imageUrl: SM, // Make sure to provide the correct path to your social media management image
      aosType: "fade-right",
      aosDelay: "400",
    },
    {
      title: "IT Training",
      description:
        "Offering professional IT training courses to equip you with the skills needed to thrive in technology.",
      imageUrl: IT, // Ensure you have the correct image path for IT Training
      aosType: "fade-up",
      aosDelay: "500",
    },
  ];

  return (
    <div className="min-h-screen bg-slate-700 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10 pt-5">
            What I Do
          </h2>
          <h2 className="text-xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-10 mb-4 p-5">
            Crafting exceptional digital experiences through innovative design and development!
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              imageUrl={service.imageUrl}
              aosType={service.aosType}
              aosDelay={service.aosDelay}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
