import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Space, Form, Input, message, Pagination } from 'antd';

const { TextArea } = Input;

const TestimonialManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [testimonialList, setTestimonialList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(null);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://myportfolio.ethioalert.com/api/testimonials');
      setTestimonialList(response.data);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      message.error('Failed to fetch testimonials');
    }
  };

  const handleAddSubmit = async (values) => {
    if (!values.name || !values.role || !values.text) {
      message.error('Please fill in all fields');
      return;
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('role', values.role);
    formData.append('text', values.text);
    formData.append('date', new Date().toISOString());

    try {
      await axios.post('https://myportfolio.ethioalert.com/api/testimonials', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      message.success('Testimonial added successfully');
      fetchTestimonials();
      form.resetFields();
    } catch (error) {
      console.error('Error adding testimonial:', error);
      message.error('Failed to add testimonial');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this testimonial?')) {
      try {
        await axios.delete(`https://myportfolio.ethioalert.com/api/testimonials/${id}`);
        message.success('Testimonial deleted successfully');
        fetchTestimonials();
      } catch (error) {
        console.error('Error deleting testimonial:', error);
        message.error('Failed to delete testimonial');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Testimonial',
      width: 800,
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Role:</strong> {record.role}</p>
          <p><strong>Message:</strong> {record.text}</p>
          <p><strong>Date:</strong> {new Date(record.date).toLocaleDateString()}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentTestimonial(record);
    updateForm.setFieldsValue({
      name: record.name,
      role: record.role,
      text: record.text,
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('role', values.role);
    formData.append('text', values.text);

    try {
      await axios.put(`https://myportfolio.ethioalert.com/api/testimonials/${currentTestimonial._id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      message.success('Testimonial updated successfully');
      fetchTestimonials();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating testimonial:', error);
      message.error('Failed to update testimonial');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Message',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleView(record)}>View</Button>
          <Button onClick={() => handleUpdate(record)}>Update</Button>
          <Button danger onClick={() => handleDelete(record._id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = testimonialList.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Testimonial Manager</h2>

      <Form form={form} onFinish={handleAddSubmit}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="role" rules={[{ required: true, message: 'Please enter the role' }]}>
          <Input placeholder="Role" />
        </Form.Item>
        <Form.Item name="text" rules={[{ required: true, message: 'Please enter the message' }]}>
          <TextArea placeholder="Message" rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Testimonial
          </Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={paginatedData} pagination={false} rowKey="_id" />

      <div className="pagination-container mt-4">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={testimonialList.length}
          onChange={handlePaginationChange}
        />
      </div>

      <Modal
        title="Update Testimonial"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="role" rules={[{ required: true, message: 'Please enter the role' }]}>
            <Input placeholder="Role" />
          </Form.Item>
          <Form.Item name="text" rules={[{ required: true, message: 'Please enter the message' }]}>
            <TextArea placeholder="Message" rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Testimonial
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TestimonialManager;
